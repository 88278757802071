<template>
  <div v-if="!loggedIn">
  {{ $gettext('Questa pagina è usabile solo dagli utenti registrati.') }}
  </div>
  <div v-if="loggedIn">
  <div v-if="userType == 1">
    <h1>
{{ $gettext('Le ricette che hai salvato') }} 
    </h1>
  </div>

  <div class="no-recipes" v-if="listing.length === 0 && !loading">
    <strong>{{ $gettext('Non hai ancora salvato nessuna ricetta.') }}</strong>
  </div>

  <div class="recipes container">
    <!-- div id="page-{{page}}"></div -->
    <recipe-card-bookmark
      v-for="item in listing"
      :key="item.id"
      :title="item.title"
      :image="item.image"
      :id="item.id"
      :item="item"
    ></recipe-card-bookmark>
  </div>
  <div class="clearfix"></div>
  <div v-if="!nomore">
    <button class="more-recipes-btn" v-if="!loading && !nomore" @click="aggiungiRicetteBook">
      {{ $gettext('Carica più ricette') }}
    </button>
    <div class="recipes container" v-if="loading">
      <strong>{{ $gettext('Cerco le ricette..') }}</strong>
    </div>
  </div>
  </div>
</template>

<script>
import RecipeCard from '@/components/recipe/RecipeCard.vue';
import recipeCardUsers from '@/components/recipe/RecipeCardUsers';
import RecipeCardBookmark from '@/components/recipe/RecipeCardBookmark'

export default {
  name: 'mybook',
  components: { RecipeCardBookmark, recipeCardUsers },


  data() {
    return {
      listing: [],
      filterBy: '',
      filterCategory: [],
      loading: false,
      userType: 1,
      page: 1,
      nomore: false,
      loggedIn : false,
      user_data: {}
    };
  },

  methods: {
    async loadMachine() {
      let data = this.$store.getters['auth/getDataProfile'];
      console.log("user data");
      console.log(data);

      this.loading = true;
      let url = this.api.getRecipeBookmark+"&page="+this.page+"&t="+Math.random();
      const config = {
        headers: {
          Authorization: 'Bearer ' + this.$store.getters['auth/getToken'],
        },
      };
      //this.listing = [];
      this.axios
        .get(url, config)
        .then((response) => {
          const listing = [];
          const start = this.listing.length;
          response.data.forEach(async (item, index) => {
            this.listing.push({
              authorName: item.author_name,
              authorId: item.author,
              id: item.id,
              title: item.title.rendered,
              image: '/images/placeholder-768x768.png',
              categoria: this.getCategoryName(item.categoria_ricetta[0]),
              item: item,
            });

            await this.loadImageURL(item.featured_media, start + index);
          });
          if (this.listing.length === 0) this.nomore = true;
          if (this.listing.length % 12 != 0) this.nomore = true;

          this.loading = false;
        })
        .catch((error) => {
          this.nomore = true;
          console.log('Error: ' + error);
        });
    },
    loadCategories() {
      this.axios
        .get(this.api.categoriesRecipe+"?" + this.$store.getters['auth/getLanguageRest'])
        .then((res) => {
          const categories = [];

          for (const data of res.data) {
            if (data.id == 29) continue; //Salto la STELLA ROUX
            categories.push({
              id: data.id,
              name: data.name,
            });
          }

          this.filterCategory = categories;
        })
        .catch((err) => {
          console.log('Error: ' + err);
        });
    },
    getCategoryName(id) {
      let name = '';
      this.filterCategory.forEach((item) => {
        if (item.id == id) name = item.name;
      });
      return name;
    },
    async loadImageURL(idM, idListing) {
      let mediaUrl = this.api.getMediaUrl + idM;

      await fetch(mediaUrl)
        .then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            console.log('Error in loading image');
          }
        })
        .then((res) => {
          this.listing[idListing].image =
            res.media_details.sizes.medium_large.source_url;
        })
        .catch((err) => {
          console.log('Error: ' + err);
        });
    },

    filter() {
      this.listing = [];
      this.page = 1;
      this.loading = true;
      this.loadMachine();
    },

    aggiungiRicetteBook() {
      this.page++;
      this.loadMachine();
    },
  },

  mounted() {
    if (this.$store.getters['auth/isLoggedIn'] == false) {
      this.$router.replace('/');
      return;
    }

    this.loggedIn = true;

    if (typeof this.$router.currentRoute.value.params.id != 'undefined') {
      this.filterBy = this.$router.currentRoute.value.params.id;
    }


    this.loadCategories();
    this.loadMachine();
  },
};
</script>

<style scoped>
.more-recipes-btn {
  margin-bottom: 30px;
}

h1 {
  margin: 0 auto 20px;
  padding-top: 20px;
  font-size: 1.5rem;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-weight: 600;
}

.card {
  position: relative;
}

.card h3 {
  margin: 0;
}

.recipes {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  max-width: 100%;
  margin: 10px auto;
}

.categoria-29 .title {
  text-align: center;
  color: white;
}
</style>
